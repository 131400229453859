<template>
  <section ref="content">
    <a-spin :spinning="loading" tip="加载中" size="large">
      <section class="wrap">
        <ZgyxSearchTablePagination
          ref="searchTablePagination"
          :formConfigs="[
            { type: 'input', label: '名称', key: 'orgName' },
            { type: 'region', label: '地区', key: ['regionArry', 'regionNo'] },
            {
              type: 'select',
              label: '当前状态',
              key: 'processStatus',
              options: processStatusList
            }
          ]"
          tableRowKey="projectResultId"
          :tableColumns="tableColumns"
          :requestFunction="requestFunction"
          :scrollConfigs="{ x: 2400 }"
        >
          <template #regionNames="{ record }">
            <a-breadcrumb>
              <a-breadcrumb-item v-for="(item, index) in record.regionNames" :key="index">{{ item }}</a-breadcrumb-item>
            </a-breadcrumb>
          </template>
          <template #orgAddress="{ record }">
            <div class="county-wrap">
              {{ record.requisition.orgAddress }}
            </div>
          </template>

          <template #countyHandleStatusText="{ record }">
            <div class="county-wrap">
              <div>状态：{{ record.countyHandleStatusText }}</div>
              <div>联系人：{{ record.countyEvaluateUserName }}</div>
              <div>联系电话：{{ record.countyEvaluateUserMobile }}</div>
              <div v-if="record.countyHandleStatus == 4">意见：{{ record.countyOpinion || '同意' }}</div>
            </div>
          </template>
          <template #cityEvaluateScore="{ record }">
            <div class="city-wrap">
              <div>审核状态：{{ record.cityHandleStatusText }}</div>
              <div>审核人：{{ record.cityEvaluateUserName }}</div>
              <div>审核时间：{{ record.cityHandleTime }}</div>
              <div>评分：{{ record.cityEvaluateScore }}分</div>
              <div>市级查询资料总分：{{ record.cityMaterialScore }}分</div>
              <div>市级现场查看总分：{{ record.citySceneScore }}分</div>
              <div>评审意见：{{ record.cityOpinion }}</div>
            </div>
          </template>

          <template #provinceEvaluateUserName="{ record }">
            <div class="province-wrap" v-if="userinfo.allowOperateType != 'read'">
              <div>评审人：{{ record.provinceEvaluateUserName }}</div>
              <div>评审时间：{{ record.provinceHandleTime }}</div>
              <div>评审总分：{{ record.provinceEvaluateScore }}分</div>
            </div>
          </template>

          <template #formExtends>
            <a-button class="btn" type="primary" danger @click="handleDownloadAll" v-preReClick> 导出</a-button>
          </template>
          <template #action="{ record }">
            <a-button type="primary" class="btn" size="small" v-if="record.canHandleType == 1" @click="toDetail(record)"
              >省级评分</a-button
            >
            <a-button type="primary" class="btn" size="small" v-if="record.canHandleType == 2" @click="toDetail(record)"
              >省级评分修改</a-button
            >
            <a-button
              type="primary"
              class="btn"
              size="small"
              v-if="record.canHandleType == 3"
              @click="toDetailByLook(record)"
              >详情</a-button
            >
            <a-button class="btn" size="small" type="primary" danger @click="handleDownloadItem(record)" v-preReClick>
              导出详情</a-button
            >
          </template>
        </ZgyxSearchTablePagination>
      </section>
    </a-spin>
  </section>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
// apis
import {
  apiGetCmsSelectionResultProvinceSearch,
  apiGetCmsSelectionResultProvinceDownLoad,
  provinceDetailDownLoad
} from '@src/apis';
// mixins
// utils
// stores
// configs
import { downloadFile2, downloadFile, isJSON } from '@src/utils';
// components
import { ZgyxSearchTablePagination } from '@src/components';

export default {
  name: 'UserManagementsComponent',
  components: { ZgyxSearchTablePagination, PlusOutlined },
  data() {
    return {
      activeUser: {}, // 当前激活的用户
      modalType: 'add', // add/edit
      loading: false,
      processStatusList: [
        {
          name: '社区已提交',
          value: 2
        },
        {
          name: '区县已核准',
          value: 3
        },
        {
          name: '市级已评分',
          value: 4
        },
        {
          name: '省级已评分',
          value: 5
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      userTypes: 'userType',
      userinfo: 'userinfo'
    }),
    requestFunction() {
      return apiGetCmsSelectionResultProvinceSearch;
    },
    tableColumns() {
      let config;
      if (this.userinfo.allowOperateType != 'read') {
        config = [
          { type: 'input', dataIndex: 'mySort', title: '序号', width: 40 },
          { type: 'input', dataIndex: ['requisition', 'orgName'], title: '申报单位名称', width: 100 },
          { type: 'custom', dataIndex: 'regionNames', title: '地区', width: 140 },
          { type: 'input', dataIndex: ['requisition', 'orgPrincipal'], title: '单位负责人', width: 100 },
          { type: 'input', dataIndex: ['requisition', 'orgMobile'], title: '联系电话', width: 100 },
          { type: 'input', dataIndex: ['requisition', 'orgFax'], title: '传真', width: 100 },
          //{ type: 'custom', dataIndex: 'orgAddress', title: '详细地址', width: 200 },
          {
            type: 'select',
            dataIndex: 'processStatus',
            title: '当前状态',
            options: this.processStatusList,
            width: 100
          },

          { type: 'input', dataIndex: 'submitApplicationTime', title: '社区提交时间', width: 120 },
          { type: 'input', dataIndex: 'selfEvaluateScore', title: '社区自评总分', width: 120 },
          { type: 'custom', dataIndex: 'countyHandleStatusText', title: '区县核准', width: 200 },
          { type: 'custom', dataIndex: 'cityEvaluateScore', title: '市级评分', width: 200 },
          { type: 'custom', dataIndex: 'provinceEvaluateUserName', title: '省级评审', width: 200 },
          { type: 'custom', dataIndex: 'action', title: '操作', width: 200, fixed: 'right', align: 'center' }
        ];
      } else {
        config = [
          { type: 'input', dataIndex: 'mySort', title: '序号', width: 40 },
          { type: 'input', dataIndex: ['requisition', 'orgName'], title: '申报单位名称', width: 100 },
          { type: 'custom', dataIndex: 'regionNames', title: '地区', width: 140 },
          { type: 'input', dataIndex: ['requisition', 'orgPrincipal'], title: '单位负责人', width: 100 },
          { type: 'input', dataIndex: ['requisition', 'orgMobile'], title: '联系电话', width: 100 },
          { type: 'input', dataIndex: ['requisition', 'orgFax'], title: '传真', width: 100 },
          //{ type: 'custom', dataIndex: 'orgAddress', title: '详细地址', width: 200 },
          {
            type: 'select',
            dataIndex: 'processStatus',
            title: '当前状态',
            options: this.processStatusList,
            width: 100
          },
          { type: 'input', dataIndex: 'submitApplicationTime', title: '社区提交时间', width: 120 },
          { type: 'input', dataIndex: 'selfEvaluateScore', title: '社区自评总分', width: 120 },
          { type: 'custom', dataIndex: 'countyHandleStatusText', title: '区县核准', width: 200 },
          { type: 'custom', dataIndex: 'cityEvaluateScore', title: '市级评分', width: 200 },
          { type: 'custom', dataIndex: 'action', title: '操作', width: 200, fixed: 'right', align: 'center' }
        ];
      }
      return config;
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {});
  },
  methods: {
    ...mapActions(['setActiveMenu']),
    //下载详情
    async handleDownloadItem(record) {
      try {
        const _this = this;
        if (_this.loading) {
          return false;
        }
        _this.loading = true;

        const { status, data, headers } = await provinceDetailDownLoad({ projectResultId: record.projectResultId });

        if (status === 200) {
          let filename = '';
          try {
            if (headers && headers['content-disposition']) {
              filename = headers['content-disposition'].split('=')[1];
            } else {
              filename = '';
            }
          } catch (error) {
            filename = '';
          }

          const { type } = data;

          new Blob([data], { type }).text().then((result) => {
            if (isJSON(result)) {
              const { message: msg } = JSON.parse(result);
              message.error(msg);
              _this.loading = false;
            } else {
              downloadFile(
                data,
                type,
                filename ? decodeURIComponent(filename) : moment(new Date()).format('YYYY-MM-DD'),
                () => {
                  message.success('导出成功');
                  _this.loading = false;
                }
              );
            }
          });
        } else {
          _this.loading = false;
          message.error('网络开小差了');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async handleDownloadAll() {
      try {
        const _this = this;
        if (_this.loading) {
          return false;
        }
        _this.loading = true;
        const query = _this.$refs.searchTablePagination.query;

        let params = Object.assign({}, query);
        let postData = { ...params };
        delete postData.regionArry;
        const { status, data, headers } = await apiGetCmsSelectionResultProvinceDownLoad(postData);

        if (status === 200) {
          let filename = '';
          try {
            if (headers && headers['content-disposition']) {
              filename = headers['content-disposition'].split('=')[1];
            } else {
              filename = '';
            }
          } catch (error) {
            filename = '';
          }

          const { type } = data;

          new Blob([data], { type }).text().then((result) => {
            if (isJSON(result)) {
              const { message: msg } = JSON.parse(result);
              message.error(msg);
              _this.loading = false;
            } else {
              downloadFile(
                data,
                type,
                filename ? decodeURIComponent(filename) : '省级清单' + moment(new Date()).format('YYYY-MM-DD'),
                () => {
                  message.success('导出成功');
                  _this.loading = false;
                }
              );
            }
          });
        } else {
          _this.loading = false;
          message.error('网络开小差了');
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 市级评分
     */
    toDetail(record) {
      this.setActiveMenu({
        path: '/provinceExamineDetail',
        name: '',
        query: { id: record.projectResultId, type: 'edit' }
      });
    },
    toDetailByLook(record) {
      this.setActiveMenu({
        path: '/provinceExamineDetail',
        name: '',
        query: { id: record.projectResultId, type: 'look' }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import './ExamineSearchComponent.less';
.county-wrap,
.city-wrap,
.province-wrap {
  width: 100%;
  height: auto;
  overflow-y: auto;
  max-height: 260px;
}
</style>
